import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './Locale'

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages : messages
});

