import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state () {
    return {
      enabled: true,
      show: false,
      faq: [{
        subject: '',
        list: [{
          question: 'Ik wil geen boostervaccinatie. Mag ik mijn afspraak aan iemand anders geven?',
          answer: 'De uitnodiging en de code zijn alleen voor u bedoeld. U mag de code niet aan iemand anders geven.',
        }, {
          question: 'Kan ik zelf kiezen welk vaccin ik krijg?',
          answer: `
De vaccinatie wordt gezet met een mRNA vaccin: Pfizer/BioNTech of Moderna. Het maakt niet uit welk vaccin iemand als 1e en 2e prik heeft gehad. Er is geen keuzemogelijkheid.
<ul>
  <li>Mensen die een booster krijgen met Pfizer/BioNTech worden gevaccineerd met dezelfde dosis als de 1e en 2e prik.</li>
  <li>Mensen die een booster krijgen met Moderna, krijgen een dosis die de helft is van wat er bij de 1e en 2e prik is gegeven.</li>
</ul>
          `,
        }, {
          question: 'Waarom is de Moderna-booster een halve dosis?',
          answer: `
Het komt vaker voor dat bij een boosterprik een kleinere dosis wordt gegeven dan bij de basisserie.
<br>
Het immuunsysteem heeft dan genoeg aan een kleinere dosis om weer aan het werk gezet te worden. Een halve dosis is dus voldoende voor een goede reactie. En het kan zijn dat een halve dosis ook minder bijwerkingen geeft.
<br>
<br>
In het onderzoek naar de Moderna-booster is een halve dosis (van 50 microgram) 6-8 maanden na de basisserie gegeven. Het bleek dat mensen een goede oppepper van hun immuunssyteem kregen. De EMAEuropean Medicines Agency heeft vervolgens de boosterprik met Moderna goedgekeurd (alleen) voor die halve dosis.
<br>
Bij Pfizer is alleen onderzoek gedaan met dezelfde dosis (30 microgram). Deze dosis is door de EMA goedgekeurd om te gebruiken in de boostercampagne. Of een kleinere dosis Pfizer ook voor net zo’n goede afweerreactie zorgt, is nog niet onderzocht
          `,
        }, {
          question: 'Is het veilig als ik de boosterprik met een ander merk vaccin krijg?',
          answer: `
Voor de boostervaccinatie kunnen allebei de mRNA-vaccins (Pfizer en Moderna) ingezet worden. Het maakt niet uit welk merk vaccin is gebruikt bij eerdere vaccinaties: Pfizer, Moderna, AstraZeneca of Janssen.
<br>
Een boostervaccinatie van een ander merk dan de eerste of tweede prik, het combineren van vaccins, staat nog niet officieel beschreven in de bijsluiters van de vaccins en de EMA registratie. Wel is uit onderzoek tot nu toe gebleken dat er geen verschil in effectiviteit en bijwerkingen is. Daarom is door de Gezondheidsraad geadviseerd dat voor de booster de vaccins gecombineerd kunnen worden.
          `,
        }, {
          question: 'Ik heb corona gehad en twee keer een prik gehaald. Moet ik nog een booster?',
          answer: 'Ook als u corona hebt gehad, krijgt u een uitnodiging voor een boosterprik. Heeft u na uw laatste vaccinatie nog corona gehad? Dan moet u zes maanden wachten voordat u de booster kan halen.',
        }, {
          question: 'Hoeveel tijd moet er tussen mijn laatste vaccinatie en de booster zitten?',
          answer: `
Er moet minimaal 3 maanden zitten tussen je laatste vaccinatie en de booster. De EMA en de ECDC hebben gezamenlijk bepaald dat het interval kan worden teruggebracht naar 3 maanden als dit nodig zou zijn in het belang van de publieke gezondheid. In Nederland is dit nu van belang in verband met de opkomst van de Omikronvariant.
<br>
Heb je na je laatste vaccinatie nog een derde prik of corona gehad? Dat heeft ook een boostereffect. Dan moet je 3 maanden wachten voordat je de booster kan halen.
          `,
        }, {
          question: 'Hoeveel tijd moet er tussen de booster en de griepprik zitten?',
          answer: 'Heeft u de griepprik eerst gekregen? Dan moet u minimaal 1 week wachten voordat u de coronavaccinatie kunt krijgen. Heeft u de coronavaccinatie het eerst gekregen? Dan moet u minimaal 2 weken wachten voordat u de griepprik kunt krijgen. Bij de GGD wordt in beide gevallen een wachttijd van 2 weken aangehouden. Dit is om logistieke redenen.',
        }, {
          question: 'Wat zijn de mogelijke bijwerkingen bij een booster?',
          answer: 'Er kunnen bijwerkingen optreden na een booster zoals dat ook kan na de 1ste en 2de prik. Het LarebLandelijke Registratie Evaluatie Bijwerkingen monitort de bijwerkingen en publiceert de gegevens daarvan op haar website.',
        }, {
          question: 'Ik ben zwanger, moet ik nu wel een boosterprik nemen?',
          answer: 'Ook als u zwanger bent, kunt u de boosterprik nemen als u hiervoor een uitnodiging heeft gekregen. Als zwangere vrouw loopt u meer risico om ernstig ziek te worden van COVID-19.',
        }, {
          question: 'Ik heb geen uitnodiging ontvangen, kan ik mezelf wel al toevoegen op de wachtlijst?',
          answer: 'Dat is niet mogelijk. U heeft een code nodig om een afspraak te maken. Die code staat in de uitnodiging die u van uw werkgever ontvangt.',
        }, {
          question: 'Ik ben mijn uitnodiging en dus mijn unieke code kwijt, hoe kan ik nu een afspraak boeken?',
          answer: 'Zonder code is het helaas niet mogelijk om een afspraak te maken. Als u de brief kwijt bent geraakt, kunt u contact op nemen met uw werkgever.',
        }, {
          question: 'Mag ik iemand meenemen naar de afspraak?',
          answer: 'Het wordt erg druk. Kom daarom het liefst alleen. Als dat niet anders kan, mag u iemand meenemen.',
        }, {
          question: 'Ik heb al een vaccinatiepaspoort. Moet ik dat meenemen naar de afspraak?',
          answer: 'Het gele vaccinatieboekje is geen officieel vaccinatiepaspoort. De GGD geeft uw vaccinatie door aan zodat het in het Digitaal Corona Certificaat (DCC) terecht komt. Met het DCC kunt u naar het buitenland reizen en in Nederland evenementen bezoeken.',
        }, {
          question: 'Krijg ik een bewijs van vaccinatie bij het prikken?',
          answer: 'U krijgt een vaccinatieregistratie mee als u bent gevaccineerd.',
        }, {
          question: 'Waar moet ik precies zijn?',
          answer: 'Nadat u een afspraak bij de GGD heeft ingepland, ontvangt u een bevestiging met het adres waar u moet zijn voor de boostervaccinatie.',
        }, {
          question: 'Hoelang duurt de afspraak?',
          answer: 'Het vaccineren zelf gaat snel. Daarna moet u nog 15 minuten blijven zitten. We kijken dan of u last krijgt van een bijwerking.',
        }, {
          question: 'Kunnen we die 15 minuten wachttijdtijd na de prik overslaan?',
          answer: `
          De 15 minuten wachttijd (observatietijd) is vastgesteld door de EMA en staat in de bijsluiter van de vaccins.
          <br>
          In de 15 minuten observatietijd treden er heel soms ernstige allergische reacties op na vaccinatie. Bij de COVID-19-vaccinaties is dat 10x vaker dan wat we gewend zijn bij bekende vaccins. Een allergische reactie moet snel vastgesteld en behandeld kunnen worden.
          `,
        }, {
          question: 'Hoelang van te voren moet ik aanwezig zijn?',
          answer: 'Kom op tijd, maar kom niet te lang van te voren in verband met de drukte.',
        }, {
          question: 'Is het vaccin wel veilig met mijn medische achtergrond?',
          answer: 'Bij uw uitnodiging zit een vragenlijst over uw gezondheid. Daarin kunt u lezen wat u moet doen als u bijvoorbeeld een stollingsstoornis of epilepsie hebt. Vul deze vragenlijst thuis in en neem mee naar de afspraak.',
        },]
      }],
    };
  },
  mutations,
  actions,
  getters,
};
