export default {
  voucherCode (state) {
    return state.voucherCode;
  },

  signedUrls (state) {
    return state.signedUrls;
  },

  isAuthenticated (state) {
    return state.hasValidVoucherCode;
  },

  hasBooking (state) {
    return state.hasBooking;
  },

  bookingInformation (state) {
    return state.bookingInformation;
  },

  bookingDate (state) {
    return state.bookingDate;
  },

  bookingContent (state) {
    return state.bookingContent;
  },

  bookingCancelUrl (state) {
    return state.bookingCancelUrl;
  },

  hasAgreedToChangeOrder (state) {
    return state.hasAgreedToChangeOrder;
  }
};
