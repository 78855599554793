import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import NotAvailableYetModule from './Modules/NotAvailableYet/index';
import MaintenanceModule from './Modules/Maintenance/index';
import VoucherCodeModule from './Modules/VoucherCode/index';
import ContactInformationModule from './Modules/ContactInformation/index';
import FaqDialogModule from './Modules/FaqDialog/index';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    NotAvailableYetModule,
    MaintenanceModule,
    VoucherCodeModule,
    ContactInformationModule,
    FaqDialogModule,
  },

  strict: debug,

  plugins: debug ? [createLogger()] : [],
});
