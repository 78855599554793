import Vue from 'vue';
import VueRouter from 'vue-router';

import Entry from './Pages/Entry.vue';
import EnterCode from './Pages/VoucherCode/EnterCode.vue';
import ContactInformation from './Pages/ContactInformation/ContactInformation.vue';
import Summary from './Pages/Summary/Summary.vue';
import Verification from './Pages/Verification/Verification.vue';
import SignedUp from './Pages/SignedUp/SignedUp.vue';
import NotAvailableYet from './Pages/NotAvailableYet.vue';
import NotFound from './Pages/Errors/NotFound.vue';

import store from './Store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Entry,
    },
    {
      path: '/vaccinatiecode',
      component: EnterCode,
    },
    {
      path: '/contactgegevens',
      component: ContactInformation,
      async beforeEnter (to, from, next) {
        try {
          await store.dispatch('VoucherCodeModule/hasValidVoucherCode');
          await store.dispatch('VoucherCodeModule/hasBooking');
          await store.dispatch('VoucherCodeModule/hasAgreedToChangeOrder');

          const voucherCode = store.getters['VoucherCodeModule/voucherCode'];
          const isAuthenticated = store.getters['VoucherCodeModule/isAuthenticated'];
          const hasBooking = store.getters['VoucherCodeModule/hasBooking'];
          const hasAgreedToChangeOrder = store.getters['VoucherCodeModule/hasAgreedToChangeOrder'];

          if (voucherCode && isAuthenticated && (!hasBooking || hasAgreedToChangeOrder)) {
            next()
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/overzicht',
      component: Summary,
      beforeEnter (to, from, next) {
        try {
          const voucherCode = store.getters['VoucherCodeModule/voucherCode'];
          const yearOfBirth = store.getters['ContactInformationModule/yearOfBirth'];
          const phonenumber = store.getters['ContactInformationModule/phonenumber'];
          const emailAddress = store.getters['ContactInformationModule/emailAddress'];

          if (voucherCode && yearOfBirth && phonenumber && emailAddress) {
            next()
          } else if (voucherCode) {
            next('/contactgegevens')
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/verifieren',
      component: Verification,
      beforeEnter (to, from, next) {
        try {
          const voucherCode = store.getters['VoucherCodeModule/voucherCode'];
          const yearOfBirth = store.getters['ContactInformationModule/yearOfBirth'];
          const phonenumber = store.getters['ContactInformationModule/phonenumber'];
          const emailAddress = store.getters['ContactInformationModule/emailAddress'];

          if (voucherCode && yearOfBirth && phonenumber && emailAddress) {
            next()
          } else if (voucherCode) {
            next('/contactgegevens')
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/aangemeld',
      component: SignedUp,
      beforeEnter (to, from, next) {
        try {
          const signedUp = store.getters['ContactInformationModule/signedUp'];

          if (signedUp) {
            next()
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/nog-niet-beschikbaar',
      component: NotAvailableYet,
    },
    {
      path: '/:notFound(.*)',
      component: NotFound
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.path === from.path) {
      return savedPosition;
    } else if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach(function (to) {
  store.dispatch('FaqDialogModule/show', (to.hash === '#faq'));
});

export default router;
