import axios from 'axios';

class Voucher {
  // eslint-disable-next-line class-methods-use-this
  validate (waiting_list_id, voucherCode) {
    return axios({
      method: 'get',
      url: 'https://api.wachtrijbooster.nl/v1/public/waiting_list/' + waiting_list_id + '/access_key/' + voucherCode,
    })
      .then(function (response) {
        return response.data;
      });
  }
  cancel (url) {
    return axios({
      method: 'POST',
      url
    })
      .then(function (response) {
        return response.data;
      });
  }
}
export { Voucher as default };
