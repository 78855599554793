export default {
  home: 'Home',
  currentPageTitle: 'Ik wil me laten vaccineren',
  landingPageTitle: 'Vul hier uw per post ontvangen vaccinatie-code in',
  landingpageIntroduction: 'Welkom...',
  vaccineCode: 'Vaccinatie-code',
  postalCode: 'Postcode',
  pleaseEnterVaccineCode: 'Voer alstublieft uw vaccinatie-code in',
  distance: 'Afstand',
  landingPageSubmitButton: 'Toon me de plaatsen in de buurt',
};
