import Vue from 'vue';

// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import router from './router';
import { i18n } from '@/SharedKernel/Translations/i18n';
import store from './Store/index';

import App from './App.vue';

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: (h) => h(App),
});
