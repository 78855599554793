export default {
  async auth (context, payload) {
    localStorage.setItem('hasValidVoucherCode', payload.hasValidVoucherCode);
    localStorage.setItem('signedUrls', JSON.stringify(payload.signedUrls));
    localStorage.setItem('hasBooking', payload.hasBooking);

    context.commit('setVoucherCode', {
      voucherCode: payload.voucherCode,
    });

    context.commit('setHasValidVoucherCode', {
      hasValidVoucherCode: payload.hasValidVoucherCode,
    });

    context.commit('setSignedUrls', {
      signedUrls: payload.signedUrls,
    });

    if (payload.hasBooking) {
      context.commit('setHasBooking', {
        hasBooking: payload.hasBooking,
      });

      context.commit('setBookingInformation', {
        bookingInformation: payload.client,
      });

      context.commit('setBookingDate', {
        bookingDate: payload.bookingDate,
      });

      context.commit('setBookingContent', {
        bookingContent: payload.bookingContent,
      });

      context.commit('setBookingCancelUrl', {
        bookingCancelUrl: payload.bookingCancelUrl,
      });
    }
  },

  hasValidVoucherCode (context) {
    const hasValidVoucherCode = localStorage.getItem('hasValidVoucherCode');
    context.commit('setHasValidVoucherCode', {
      hasValidVoucherCode,
    });
  },

  hasBooking (context) {
    const hasBooking = localStorage.getItem('hasBooking') === 'true';
    context.commit('setHasBooking', {
      hasBooking,
    });
  },

  hasAgreedToChangeOrder (context) {
    const hasAgreedToChangeOrder = true
    context.commit('setHasAgreedToChangeOrder', {
      hasAgreedToChangeOrder,
    });
  }
};
