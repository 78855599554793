export default {
  setZipcode (state, zipcode) {
    state.zipcode = zipcode;
  },
  setYearOfBirth (state, yearOfBirth) {
    state.yearOfBirth = yearOfBirth;
  },
  setEmailAddress (state, emailAddress) {
    state.emailAddress = emailAddress;
  },
  setPhonenumber (state, phonenumber) {
    state.phonenumber = phonenumber;
  },
  setVerifiedPhonenumber (state, verified) {
    state.verifiedPhonenumber = verified;
  },
  setFinalizationUrl (state, signedUrl) {
    state.finalizationUrl = signedUrl;
  },
  setAttendee (state, attendee) {
    state.attendee = attendee;
  },
  setSignedUp (state, signedUp) {
    state.signedUp = signedUp;
  }
};
