export default {
  setVoucherCode (state, payload) {
    state.voucherCode = payload.voucherCode;
  },

  setHasValidVoucherCode (state, payload) {
    state.hasValidVoucherCode = payload.hasValidVoucherCode;
  },

  setSignedUrls (state, payload) {
    state.signedUrls = payload.signedUrls;
  },

  setHasBooking (state, payload) {
    state.hasBooking = payload.hasBooking;
  },

  setBookingInformation (state, payload) {
    state.bookingInformation = payload.bookingInformation;
  },

  setBookingDate (state, payload) {
    state.bookingDate = payload.bookingDate;
  },
  setBookingContent (state, payload) {
    state.bookingContent = payload.bookingContent;
  },

  setBookingCancelUrl (state, payload) {
    state.bookingCancelUrl = payload.bookingCancelUrl;
  },

  setHasAgreedToChangeOrder (state, payload) {
    state.hasAgreedToChangeOrder = payload.hasAgreedToChangeOrder;
  },

  resetBookingInformationState (state) {
    state.bookingCancelUrl = null
    state.hasAgreedToChangeOrder = false
    state.bookingDate = null
    state.hasBooking = false
    state.bookingInformation = []
    state.bookingContent = false
    localStorage.removeItem('hasBooking')
  }
};
