export default {
  enabled (state) {
    return state.enabled;
  },
  show (state) {
    return state.show;
  },
  faq (state) {
    return state.faq;
  }
};
