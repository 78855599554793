export default {
  contactInformation (context, payload) {
    context.commit('setZipcode', payload.zipcode);
    context.commit('setYearOfBirth', payload.yearOfBirth);
    context.commit('setEmailAddress', payload.emailAddress);
    context.commit('setPhonenumber', payload.phonenumber);
    context.commit('setVerifiedPhonenumber', payload.verifiedPhonenumber);

    localStorage.setItem('contactInformation', JSON.stringify(payload));
  },

  hasContactInformation (context) {
    const contactInformation = JSON.parse(localStorage.getItem('contactInformation'));

    if (!contactInformation) {
      return;
    }

    context.commit('setZipcode', contactInformation.zipcode);
    context.commit('setYearOfBirth', contactInformation.yearOfBirth);
    context.commit('setEmailAddress', contactInformation.emailAddress);
    context.commit('setPhonenumber', contactInformation.phonenumber);
    context.commit('setVerifiedPhonenumber', contactInformation.verifiedPhonenumber);
  },

  resetData (context) {
    context.commit('setZipcode', null);
    context.commit('setYearOfBirth', null);
    context.commit('setEmailAddress', null);
    context.commit('setPhonenumber', null);
    context.commit('setVerifiedPhonenumber', false);
  },

  setAttendee (context, payload) {
    context.commit('setAttendee', payload);
  },

  signedUp (context, signedUp) {
    context.commit('setSignedUp', signedUp);
  },
};
