import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state() {
    return {
      voucherCode: null,
      hasValidVoucherCode: false,
      signedUrls: [],
      hasBooking: false,
      bookingInformation: [],
      bookingDate: null,
      bookingContent: [],
      hasAgreedToChangeOrder: false,
      bookingCancelUrl: null
    };
  },
  mutations,
  actions,
  getters,
};
