export default {
  zipcode (state) {
    return state.zipcode;
  },
  yearOfBirth (state) {
    return state.yearOfBirth;
  },
  emailAddress (state) {
    return state.emailAddress;
  },
  phonenumber (state) {
    return state.phonenumber;
  },
  verifiedPhonenumber (state) {
    return state.verifiedPhonenumber;
  },
  attendee (state) {
    return state.attendee;
  },
  signedUp (state) {
    return state.signedUp;
  },
};
