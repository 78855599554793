export default {
  home: 'Home',
  currentPageTitle: 'I want to get vaccinated',
  landingPageTitle: 'Enter your vaccination-code received by post',
  landingpageIntroduction: 'Welcome...',
  vaccineCode: 'Vaccine-code',
  postalCode: 'Postal code',
  pleaseEnterVaccineCode: 'Please enter your vaccine-code',
  distance: 'Distance',
  landingPageSubmitButton: 'Show me the places nearby',
};
