<template>
  <div
    class="alert"
    :class="mode"
  >
    <icon
      :type="icon"
      v-if="isWithIcon"
      :role="role"
      :aria-label="ariaLabel"
    ></icon>
    <slot></slot>
  </div>
</template>

<script>
import Icon from '@/SharedKernel/Atoms/Icons/Icon.vue';

export default {
  name: 'Alert',
  components: { Icon },
  props: {
    mode: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    role: {
      type: String,
      required: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
  },

  computed: {
    isWithIcon() {
      return this.mode.includes('with-icon');
    },
  },
};
</script>

<style scoped>
.alert {
  position: relative;
  padding: 0.5rem;
  border: 1px solid #000;
  background: #fff4dc;
  text-align: left;
}
.alert.error {
  border-color: red;
  background: #f9dfdd;
  color: #000;
}
.alert.valid {
  border-color: #39870C;
  background: #E1EDDB;
  color: #000;
}
.with-icon {
  padding: 1rem 2rem 1rem 4rem;
}
.alert .ro-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  left: 1rem;
  top: calc(1rem);
  background-position: 50%;
  background-size: 75%;
  background-repeat: no-repeat;
  border-radius: 100%;
}
</style>
