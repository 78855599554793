import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state() {
    return {
      zipcode: null,
      yearOfBirth: null,
      emailAddress: null,
      phonenumber: null,
      verifiedPhonenumber: false,
      attendee: null,
      signedUp: false,
    };
  },
  mutations,
  actions,
  getters,
};
