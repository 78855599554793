<template>
  <div>
    <the-header></the-header>
    <div class="wrapper">
      <not-available-yet v-if="notAvailableYet"></not-available-yet>
      <maintenance v-else-if="maintenanceIsActive"></maintenance>
      <router-view v-else></router-view>
    </div>
    <the-footer></the-footer>
  </div>
</template>
<script>

import TheHeader from '@/SharedKernel/Templates/TheHeader.vue';
import TheFooter from '@/SharedKernel/Templates/TheFooter.vue';
import NotAvailableYet from './Pages/NotAvailableYet.vue';
import Maintenance from './Pages/Maintenance/Maintenance.vue';
import Session from '@/Infrastracture/Session';
const LocalSession = new Session();
export default {
  components: {
    TheFooter,
    TheHeader,
    NotAvailableYet,
    Maintenance,
  },
  computed: {
    notAvailableYet () {
      console.log(this.$store.getters["NotAvailableYetModule/enabled"]);
      return this.$store.getters["NotAvailableYetModule/enabled"];
    },
    maintenanceIsActive () {
      return this.$store.getters["MaintenanceModule/isActive"];
    }
  },
  created () {
    if (LocalSession.hasInvalidSession()) {
      LocalSession.clear()
      LocalSession.setClientSession()
    }

    this.$store.dispatch('VoucherCodeModule/hasValidVoucherCode');
    this.$store.dispatch('VoucherCodeModule/hasBooking');
    this.$store.dispatch('ContactInformationModule/hasContactInformation');
  },
};
</script>

<style type="text/css">
@import "./assets/css/fonts.css";
@import "./assets/css/gt.css";
@import "./assets/css/main.css";
</style>

<style scoped>
.wrapper {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  overflow: hidden;
  padding: 60px 20px;
  position: relative;
}
</style>
