<template>
  <div>
    <faq-dialog></faq-dialog>
    <header>
      <div class="wrap">
        <div id="custom-logo-container">
          <img
            src="../../assets/images/logo.svg"
            alt="Rijksoverheid"
          />
        </div>

        <!-- <nav id="custom-language-nav">
          <ul>
            <li
              v-for="language in languages"
              :key="language"
              :class="{active: language == $i18n.locale}"
            ><a
              :href="'/' + language"
              @click.prevent="selectLanguage(language)"
            >{{ language }}</a></li>
          </ul>
        </nav> -->
      </div>
    </header>

    <div id="custom-breadcrumb">
      <div class="wrap">
        <h1>
          <router-link to="/">{{ $t("home") }}</router-link>
          <span class="icon arrow-head-right"></span>
          {{ $t("currentPageTitle") }}
        </h1>
        <a
          href="#faq"
          id="faq-link"
          v-if="faqEnabled"
        >Veelgestelde vragen</a>
      </div>
    </div>
  </div>
</template>

<script>
import FaqDialog from '@/SharedKernel/Molecules/Dialogs/FaqDialog.vue';

export default {
  name: 'TheHeader',
  components: {
    FaqDialog,
  },
  data () {
    return {
      languages: ['nl', 'en'],
    };
  },
  computed: {
    faqEnabled() {
      return this.$store.getters['FaqDialogModule/enabled'];
    }
  },
  methods: {
    selectLanguage (language) {
      this.$i18n.locale = language;
    },
    showFaqDialog() {
      this.$store.dispatch('FaqDialogModule/show', true);
    }
  },
};
</script>

<style scoped>
/************************************
header
*************************************/

header {
  height: 100px;
  background: #ffffff;
}

header .wrap {
  position: relative;
  height: 100%;
  margin: 0 auto;
}

header .wrap:after {
  content: "";
  display: block;
  clear: both;
}

#custom-logo-container {
}

#custom-logo-container img {
  display: block;
  width: 40px;
  margin: 0 auto;
}

#custom-logo-container a:hover {
  opacity: 0.7;
}

#custom-language-nav {
  position: absolute;
  top: 20px;
  right: 10px;
}
#custom-language-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#custom-language-nav li {
  display: inline-block;
  border-bottom: 2px solid transparent;
  margin: 0 0 0 5px;
  vertical-align: middle;
}

#custom-language-nav li:hover,
#custom-language-nav .active {
  border-bottom-color: #01689a;
}

#custom-language-nav a {
  padding: 0 0.3rem;
  color: inherit;
  font-size: 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  header {
    height: 140px;
  }
  #custom-logo-container img {
    width: 54px;
  }
}

#custom-breadcrumb {
  display: flex;
  height: 70px;
  padding: 0.625rem;
  background: #ca005d;
  font-size: 1.5rem;
  line-height: 1;
  align-items: center;
}

#custom-breadcrumb .wrap {
  position: relative;
  width: 100%;
  max-width: 700px;
  padding: 0 10px;
  margin: 0 auto;
}

#custom-breadcrumb h1 {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  color: #ffffff;
  font: inherit;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#custom-breadcrumb h1 a {
  display: inline-block;
  margin: 2px;
  color: inherit;
  font-weight: 700;
  text-decoration: none;
}

#custom-breadcrumb h1 a:hover {
  text-decoration: underline;
}
#custom-breadcrumb .icon {
  display: inline-block;
  margin: 0 5px;
  font-size: 20px;
}

#faq-link {
  padding: 0;
  border: 0;
  background: none;
  color: #fff;
  font: inherit;
  font-size: 1.2rem;
  text-decoration: underline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (min-width: 768px) {
  #custom-breadcrumb {
    font-size: 1.8rem;
  }
  #custom-breadcrumb .wrap {
    max-width: none;
    padding: 0;
  }
  #faq-link {
    position: absolute;
    top: 50%;
    right: 100px;
    margin-top: -9px;
  }
}
</style>
