<template>
  <div class="wrap">
    <footer></footer>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>

<style scoped>
</style>
