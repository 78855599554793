<template>
  <div class="container">
    <section>
      <p>Een code is naar uw telefoonnummer gestuurd. Vult dit in ter controle dat u het bent.</p>
    </section>
    <section id="code">
      <input
        type="text"
        maxlength="1"
        autocomplete="off"
        pattern="[0-9]*"
        inputmode="numeric"
        autofocus
        v-for="(code, index) in codes"
        :key="index"
        v-model="code.value"
        @keyup="focusCode"
      >
    </section>
    <section v-if="verificationError">
      <alert
        mode="error with-icon"
        icon="error"
        role="img"
        aria-label="Foutmelding"
      >{{ verificationError }}</alert>
    </section>
    <section>
      <base-button
        type="button"
        :disabled="code.length !== 5 || verifying"
        @click.native.prevent="submitCode"
      >
        Controleren
        <div class="indicators">
          <ring-spinner v-if="verifying"></ring-spinner>
          <img
            src="../../../assets/images/checked.svg"
            alt="Gecontroleerd"
            v-if="verified && !verifying"
          />
        </div>
      </base-button>
    </section>
  </div>
</template>

<script>
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import RingSpinner from '@/SharedKernel/Atoms/Spinners/RingSpinner.vue';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';

import Verification from '@/Infrastracture/Verification';
const VerificationResource = new Verification();

export default {
  components: {
    BaseButton,
    RingSpinner,
    Alert,
  },
  data () {
    return {
      codes: [{
        value: '',
      }, {
        value: '',
      }, {
        value: '',
      }, {
        value: '',
      }, {
        value: '',
      }],
      verificationError: false,
      verifying: false,
      verified: false,
    };
  },

  computed: {
    code () {
      let code = '';

      this.codes.forEach((data) => {
        code += data.value;
      });

      return code;
    },
    emailAddress() {
      return this.$store.getters['ContactInformationModule/emailAddress'];
    },
    phonenumber() {
      return this.$store.getters['ContactInformationModule/phonenumber'];
    },
    attendeeId() {
      return this.$store.getters['ContactInformationModule/attendee'];
    }
  },

  methods: {
    submitCode () {
      if (this.code.length !== 5 || this.verifying) {
        return;
      }

      this.verifying = true;

      const waiting_list_id = '01FNRB4G2FMC6QM67D3VHANJPZ';
      VerificationResource
        .verify(waiting_list_id, this.attendeeId, this.code)
        .then((data) => {
          this.verified = data;
          this.verifyCode();
        })
        .catch((error) => {
          console.log('error', error);
          this.verificationError = 'Er is iets misgegaan probeer het over een paar seconden opnieuw.';
        })
        .finally(() => {
          this.verifying = false;
        });
    },

    verifyCode () {
      this.$emit('verify-code', this.verified);
    },

    focusCode (e) {
      const target = e.srcElement || e.target;
      const maxLength = parseInt(target.attributes["maxlength"].value);
      const myLength = target.value.length;

      this.verificationError = false;

      if (myLength >= maxLength) {
        let next = target;
        while (next.nextElementSibling) {
          next = next.nextElementSibling;
          if (next == null) {
            break;
          }
          if (next.tagName.toLowerCase() === "input") {
            next.focus();
            next.select();
            break;
          }
        }
      } else if (myLength === 0) {
        let previous = target;
        while (previous.previousElementSibling) {
          previous = previous.previousElementSibling;
          if (previous == null) {
            break;
          }
          if (previous.tagName.toLowerCase() === "input") {
            previous.focus();
            previous.select();
            break;
          }
        }
      }
    },
  }
}
</script>

<style scoped>
.container {
  padding: 30px;
  background: #01689a;
  color: #fff;
  text-align: center;
}
section {
  margin: 0 0 10px;
}
#code {
  padding: 20px 0;
}
#code input {
  height: 50px;
  width: 33px;
  padding: 0;
  border: none;
  margin: 0 5px;
  background: #fff;
  color: #000;
  text-align: center;
}
button {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  background: #fff;
  color: #01689a;
}
button:hover {
  background: #fff;
  opacity: 0.8;
}
button:disabled {
  background: #fff;
  opacity: 0.8;
}
button .indicators {
  position: absolute;
  display: flex;
  top: 0;
  right: 15px;
  height: 100%;
  align-items: center;
}
button .loader {
  font-size: 2px;
}
</style>
