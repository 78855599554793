<template>
  <div
    class="dialog"
    v-if="showDialog"
    @click="closeDialog"
  >
    <div class="wrap">
      <div
        class="window"
        @click.stop
      >
        <button
          class="close-dialog"
          @click.prevent="closeDialog"
        >
          <icon
            type="close"
            aria-label="Sluit veelgestelde vragen"
          ></icon>
        </button>
        <div class="content">
          <h1>Veelgestelde vragen</h1>
          <section
            v-for="item in faqData"
            :key="item.subject"
          >
            <h2 v-if="item.subject">{{ item.subject }}</h2>
            <ul class="faq-list">
              <li
                v-for="item in item.list"
                :key="item.question"
                class="faq-item"
              >
                <button type="button"
                  class="question"
                  @click="toggleAnswer"
                >{{ item.question }}</button>
                <div
                  class="answer"
                  v-html="item.answer"
                ></div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/SharedKernel/Atoms/Icons/Icon.vue';

export default {
  components: { Icon },
  data () {
    return {
      path: this.$route.path,
    }
  },
  computed: {
    showDialog () {
      return this.$store.getters['FaqDialogModule/show'];
    },
    faqData () {
      return this.$store.getters['FaqDialogModule/faq'];
    }
  },
  methods: {
    closeDialog () {
      this.$router.push(this.path);
    },
    toggleAnswer (e) {
      console.log(e.target.parentElement.classList.toggle('active'));
    }
  }
}
</script>

<style scoped>
.dialog {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.dialog .wrap {
  display: table-cell;
  vertical-align: middle;
}

.dialog .window {
  position: relative;
  overflow: auto;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 640px;
  padding: 50px 10px;
  margin: 0 auto;
  background: #f3f3f3;
}

.dialog .close-dialog {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  padding: 0;
  border: 0;
  background: none;
}
.dialog .content {
  overflow: auto;
  height: 100%;
  max-height: 100%;
}
h1 {
  text-align: center;
}
.faq-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.faq-item {
  border-bottom: 1px solid #01689a;
}
.faq-item:first-child {
  border-top: 1px solid #01689a;
}
.faq-list .question {
  display: block;
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 30px;
  border: none;
  font: inherit;
  color: #000;
  text-align: left;
  cursor: pointer;
}
.faq-list .question:before,
.faq-list .question:after {
  content: "";
  top: 50%;
  position: absolute;
  background: #01689a;
}
.faq-list .question:before {
  left: 5px;
  height: 14px;
  width: 4px;
  margin-top: -7px;
}
.faq-list .question:after {
  left: 0;
  height: 4px;
  width: 14px;
  margin-top: -2px;
}
.faq-list .answer {
  overflow: hidden;
  max-height: 0;
  padding: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
/*** .active ***/
.active .question:before {
  display: none;
}
.active .answer {
  max-height: 1000px;
  padding-bottom: 10px;
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .dialog .window {
    padding: 50px 25px 25px;
  }
}
</style>
