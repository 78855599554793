<template>
  <span
    class="ro-icon"
    :class="'ro-icon-' + type"
  ></span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.ro-icon {
  display: inline-block;
}
.ro-icon-warning {
  width: 32px;
  height: 32px;
  background-position: 50%;
  background-size: 75%;
  background-repeat: no-repeat;
  border-radius: 100%;
  background-image: url(../../../assets/images/warning.svg);
}
.ro-icon-error {
  width: 32px;
  height: 32px;
  background-color: red;
  background-position: 50%;
  background-size: 75%;
  background-repeat: no-repeat;
  border-radius: 100%;
  background-image: url(../../../assets/images/error.svg);
}
.ro-icon-valid {
  width: 32px;
  height: 32px;
  background-position: 50%;
  background-size: 75%;
  background-repeat: no-repeat;
  border-radius: 100%;
  background-image: url(../../../assets/images/valid.svg);
}
.ro-icon-close {
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../../assets/images/close.svg);
}
</style>
