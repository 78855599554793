
class Session {
  clear () {
      localStorage.clear()
  }

  setClientSession () {
    const expiresAt = new Date();
    expiresAt.setTime(expiresAt.getTime() + (4 * 60 * 60 * 1000))
    localStorage.setItem('expires_at', expiresAt.getTime())
  }

  hasValidSession() {
    if (this.getExpiresAt() === null) {
      return false;
    }

    return new Date().setTime(this.getExpiresAt()) > new Date().getTime();
  }

  hasInvalidSession() {
    return this.hasValidSession() === false;
  }

  getExpiresAt() {
    return localStorage.getItem('expires_at');
  }
}

export { Session as default };
