<template>
  <main>
    <section>
      <h1>U bent aangemeld</h1>

      <p>Bedankt voor uw aanmelding. U bent nu aangemeld voor de wachtrij. Als zorgmedewerker heeft u hiermee versnelde toegang voor een boostervaccinatie. U ontvangt ook een bevestiging per e-mail.</p>

      <p>Zodra u een afspraak kunt inplannen, ontvangt u een sms/e-mail van de GGD. Hierin staat meer informatie over het plannen van een afspraak. Het oproepen gebeurt op basis van postcode en leeftijd.</p>

      <p>Zorgmedewerkers krijgen met voorrang de mogelijkheid om een afspraak te maken voor een boostervaccinatie. Er worden dagelijks meer tijdsloten opengezet bij de GGD en er wordt extra ruimte gecreëerd voor afspraken. De verwachte planning is dat alle zorgmedewerkers van 18 jaar of ouder vóór 1 januari uitgenodigd zullen zijn.</p>

      <p>Met vriendelijke groet,
        <br>
        Team COVID-19-vaccinaties RIVM
      </p>

      <p>
        <router-button to="/">Ga terug naar home</router-button>
      </p>
    </section>
  </main>
</template>

<script>
import Session from '@/Infrastracture/Session';
import RouterButton from '../../SharedKernel/Atoms/Buttons/RouterButton.vue';
const LocalSession = new Session();

export default {
  components: { RouterButton },
  created () {
    LocalSession.clear();
    this.$store.dispatch('ContactInformationModule/resetData');
  },
}
</script>
